@import "./../theme";

$transition-time: 0.4s;
$sidebar-contents-width: 360px;
$sidebar-buttons-width: 50px;

.AppSidebar {
  position: fixed;
  right: 0;
  left: auto;
  top: 0;
  bottom: auto;
  height: 100vh;
  width: $sidebar-buttons-width;
  transition: width $transition-time;

  &.open {
    width: ($sidebar-contents-width + $sidebar-buttons-width);

    .AppSidebar-contents {
      width: $sidebar-contents-width;
    }
  }

  .AppSidebar-buttons {
    width: $sidebar-buttons-width;
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
    bottom: 0;

    .AppSidebarButton:first-child {
      margin-top: 0 !important;
    }

    .AppSidebarButton:last-child {
      margin-bottom: 0 !important;
    }

    .AppSidebarButton-admin {
      position: absolute;
      bottom: 0;
      right: auto;
      left: 0;
      top: auto;
    }
  }

  .AppSidebar-subheader {
    font-weight: lighter;
    font-size: 1.2rem;
    text-transform: capitalize;
  }

  .AppSidebar-contents {
    box-shadow: -1px 0px 5px 0px #aaa;
    transition: width $transition-time;
    width: 0;
    background: #fff;
    position: absolute;
    left: 50px;
    right: auto;
    top: 0;
    bottom: 0;
    overflow-y: scroll;
    height: 100vh;
    padding: 8px 0;

    .AppSidebarContent {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      .AppSidebarContent-inner {
        opacity: 0;
        transition: opacity 0.1s $transition-time;
      }

      &.active {
        visibility: visible;

        .AppSidebarContent-inner {
          opacity: 1;
        }
      }
    }
  }

  .AppSidebarButton {
    height: 200px;
    width: $sidebar-buttons-width;
    opacity: 0;
    transition: opacity $transition-time;

    &.active {
      opacity: 1;
      cursor: pointer;
    }

    &.AppSidebarButton-admin {
      height: 160px;
      background: #8e7100 url("../../assets/sidebar_admin.png") no-repeat center center;
    }

    &.AppSidebarButton-user {
      background: #265959 url("../../assets/sidebar_user.png") no-repeat center center;
    }

    &.AppSidebarButton-seller {
      background: #592626 url("../../assets/sidebar_seller.png") no-repeat center center;
    }
  }

  .AppSidebarContent-seller {
    .nav-pills {
      border: 1px solid $text-color-black;
      margin-bottom: 0;

      .nav-item {
        background: #fff;
        border-right: 1px solid $text-color-black;
        color: $text-color-black;
        flex-basis: 33.33%;
        text-align: center;
        font-size: 0.8rem;
        font-weight: 500;

        button {
          border: none;
          background: #fff;
          width: 100%;
          height: fit-content;
          color: $text-color-black;
          padding: 0.75rem 0.25rem;

          img {
            display: block;
            height: 40px;
            margin: 0 auto 0.25rem;
          }

          &.active {
            background: #f9fae9;
          }

          &:last-child {
            border-right: none;
          }

          &:focus {
            outline: none;
          }
        }
     }
    }
    .tabs-content {
      padding: 8px 0;
    }

    .card {
      border: none;
      background-color: transparent;
      background-clip: initial;
    }

    .card-header {
      border-bottom: 0;
      background-color: transparent;
      padding: 0.5rem 0;
      font-size: 1.5rem;
      font-weight: 300;
    }

    .card-body {
      padding: 0.5rem 0;
    }

    #seller-content-tabs-tabpane-editCustomer form > .form-group:last-child {
      min-height: 100px;
    }

    textarea[name="note"] {
      height: 300px;
      resize: none;
    }
    textarea[name="notes"] {
      height: calc(100vh - 200px);
      min-height: 100px;
      resize: none;
    }

    textarea[name="companyOtherOwners"] {
      height: 100px;
      resize: none;
    }

    .tol-code-value {
      font-size: small;
      color: #5c5c5c;
    }

    .InsuranceTabs {
      margin-top: 2rem;

      .nav-item {
        flex-basis: 25%;
      }
    }

    .custom-invalid-feedback {
      width: 100%;
      margin-top: 0.25rem;
      font-size: 80%;
      color: #dc3545;
    }

    .sales-checkbox .form-check-label::before,
    .sales-checkbox .form-check-label::after {
      width: 1.55rem;
      height: 1.55rem;
    }

    .sales-checkbox .form-check-label {
      top: 0.2rem;
      padding-left: 10px;
      font-weight: 300;
      font-size: medium;
    }
  }

  .AppSidebarContent-beneficiary {
    background-color: white;
  }

  .AppSidebarAccordionHeader {
    position: relative;
    padding-left: 24px;

    .AppSidebarAccordionHeader-icon {
      position: absolute;
      top: 0;
      left: 0;
    }

    .AppSidebarAccordionHeader-title {
      text-transform: uppercase;
      font-size: 1.1rem;
      font-weight: bolder;
    }

    .validityIcon {
      height: 16px;
      width: 16px;
      margin-bottom: 4px;
    }
  }
}
