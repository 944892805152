.Booker {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;

  input {
    height: 100%;
    width: 300px;
    margin-right: 10px;
  }
}
