.ActiveInActiveToggle{
  border: #265959 solid 1px;
  border-radius: 10px;

  button{
    border-radius: 9px;
  }
}

.AdminSellerToggle {
  height: 100%;
  max-height: 38px;
  align-self: center;
}

.UserAlerts{
  position: absolute;
  top: 100px;
  right: 0;
  width: 250px;
  text-align: center;
}
