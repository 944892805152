@import "./../theme";

@import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.scss";

/*  In Bootstrap 5 form-specific layout classes were dropped.
    These classes from v4 are needed until we rewrite the form layouts */
.form-group {
  margin-bottom: 1rem;
}

.form-inline .form-control {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col {
  padding-left: 5px;
  padding-right: 5px;
}

label {
  margin-bottom: 0.5rem;
}
/* ******************************************************************* */

.AppSidebar,
.ProspectCreate,
.ProspectEdit {
  .MeetingEdit-partnership-logo {
    img {
      max-width: 100%;
      height: auto;
    }
  }

  .form-group {
    .form-label {
      font-size: 1.1rem;
      font-weight: lighter;
    }

    .form-control.is-invalid {
      padding-right: 0;
      background: #fff;
    }

    .form-control.is-readonly {
      background: #fff;
    }

    select.form-control,
    textarea.form-control,
    input.form-control {
      border-color: $text-color-black;
      color: $text-color-black;
      font-size: 1.25rem;
      font-weight: 300;
    }

    .input-group {
      input.form-control {
        border-right: 0;
      }
      .input-group-text,
      .input-group-link {
        border: 1px solid $text-color-black;
        border-left-color: #fff;
        color: #888888;
        background: #fff;
      }
      .input-group-link{
        border: none;
      }
    }
  }

  .margin-auto-col {
    .col-sm-1,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6{
      margin:  auto;
    }
  }
}

.ProspectCreate {
  .form-group {
    .form-label {
      font-size: 1.5rem;
      font-weight: 500;
    }
  }

  .btn-create-form {
    padding: 6px 60px;
    font-size: 1.25rem;
    font-weight: 300;
  }
}

.FormikCheckbox {
  .btn-group {
    border: 1px solid $text-color-black;
    padding: 0;
    height: auto;

    button {
      line-height: 1.8;
    }

    button:last-child {
      border-left: 1px solid $text-color-black;
      z-index: 1;
    }
  }
}

.FormikRadioGroup {
  .btn-group {
    border: 1px solid $text-color-black;
    padding: 0;
    height: 44px;
  }

  button {
    padding: 0;
  }

  button:hover,
  button:active,
  button:focus,
  button {
    line-height: 1.8;
    border-left: 1px solid $text-color-black;
    z-index: 1;
    font-weight: lighter;
    font-size: 1.1rem;
  }

  button:first-child {
    border: none;
  }
}

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker-popper {
  z-index: 2;
  min-width: 330px;
}

.salesStatePickerWrapper {
  button{
    border-radius: 25px;
    width: 100%;
    text-transform: uppercase;
  }

  .dropdown-menu {
    outline: auto;
    padding: 0;
    border-radius: 25px;
    background-color: $btn-primary-background-color;
    text-align: center;
    text-transform: uppercase;

    a{
      color: #fff;
    }

    a:first-child{
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
    }

    a:last-child {
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
    }
    a:hover {
      color:black;
    }
  }
}

.filter-input{
  border-color: $text-color-black;
}

.clear-find-prospects {
  background-color: $btn-info-background-color;
  border-color: $text-color-black !important;
  border-left: none !important;
  cursor: pointer;
}

.red-link-word{
  font-size: inherit !important;
}

.table-filters{
  margin: 0;
}

.applied-discount-text{
  color: $btn-primary-background-color;
}

.FormikOccupation {
  .container {
    margin: 5px 2px 0 0;
  }

  .FormikInput {
    margin: 0;
  }
}

.FieldsInBorder{
  border: #888888 solid thin;
  padding: 2px;
  margin-bottom: 5px;

  .row{
    padding: 0 10px 0 10px;
  }

  .FormikInput {
    margin: 0;
  }
}

.CustomInvalidFeedback{
  font-size: 0.875rem;
  color: #dc3545;
}

.form-control:disabled + .input-group-text {
  background-color: var(--bs-secondary-bg) !important;
}

.AddButton {
  height: 100%;
  width: 100%;
}

.form-check {
  display: flex;
  justify-items: center;

  .form-check-label{
    margin-top: 1px;
    margin-left: 5px;
  }
}

.today-button{
  border: #aaaaaa;
  font-weight: bold;
  font-size: 0.8rem;
}
