@import "./../theme";

.TableList {
  table {
    th {
      border-bottom: none;
      border-top: none;
      color: $table-header-text-color;
      font-size: 1.5rem;
      font-weight: 400;

      &:focus {
        outline: none;
      }
    }

    td {
      font-size: 1.5rem;
      font-weight: 300;
      color: $table-body-text-color;
    }

    tbody tr:first-child {
      td {
        border-top: none;
      }
    }

    th:first-child,
    td:first-child {
      padding-left: 0;
    }

    th:last-child,
    td:last-child {
      padding-right: 0;
    }
  }

  .pagination {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .list-group-item{
      cursor: pointer;
      height: 41px;
      width: 46px;
      display: flex;
      justify-content: center;
    }
  }

  .enable {
    cursor: pointer;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  span::before {
    content: "↑";
    color: #888;
    opacity: 0.4;
  }

  span::after {
    content: "↓";
    color: #888;
    opacity: 0.4;
  }

  /* Styles for the "asc" state */
  span.asc::before,
  span.desc::before {
    color: #070b0c;
    opacity: unset;
  }

  /* Styles for the "desc" state */
  span.desc::before{
    color: #888;
    opacity: 0.4;
  }
  span.desc::after {
    color: #070b0c;
    opacity: unset;
  }
}
