@import "./../theme";

.LoginLayout {
  background: $login-background-color;
  min-height: 100vh;
  position: relative;
}

.LoginLogo {
  margin: 200px 0 40px;
  width: 100%;

  img {
    height: 88px;
    margin: 0 auto;
    display: block;
  }

  .h1 {
    color: $text-color-white;
  }
}
