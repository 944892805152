@import "./theme";
@import "./themeBootstrap";
@import "./../../node_modules/react-datepicker/dist/react-datepicker.css";

@import "./partials/App";
@import "./partials/AppHeader";
@import "./partials/AppSidebar";
@import "./partials/LoginLayout";
@import "./partials/DefaultLayout";
@import "./partials/StickyImageLayout";
@import "./partials/Home";
@import "./partials/LoginRegister";
@import "./partials/Prospects";
@import "./partials/TableList";
@import "./partials/Form";
@import "./partials/Booker";
@import "./partials/Admin";

.popover.HistoryPopover {
  transform: unset !important;
  right: 0 !important;
  left: auto !important;
  position: fixed !important;
}

.clickable {
  cursor: pointer;
}

.link {
  text-decoration: underline;
}

.btn-logout {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.readonlyLink {
  color: $btn-primary-background-color;
}

.logo-title {
  line-height: 1.25;
  font-weight: $font-weight-bold;
  margin-left: 0.5rem;
}

.IconButton {
  cursor: pointer;
}
