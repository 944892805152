@import "src/styles/themeBootstrap";

.editProspectLink,
.presentationProspectLink {
  width: 32px;
  height: 32px;
  display: inline-block;
}

.ProspectLinks {
  text-align: right;
}

.react-bootstrap-table table {
  table-layout: auto;
}

.ProspectInsuranceSummary {
  background: #f9fae9;
  width: 100%;
  position: relative;
  padding-bottom: 105px !important;

  .ProspectInsuranceSummary-inner {
    transition: opacity 0.5s;
    opacity: 1;
  }

  &.hidden {
    background: #fff;

    .ProspectInsuranceSummary-inner {
      opacity: 0;
    }
  }

  .insurance-letter {
    display: inline-block;
    width: 64px;
    min-width: 64px;
    height: 64px;
    background: $btn-primary-background-color;
    border: 1px solid #cecece;
    text-align: center;

    span {
      display: inline-block;
      line-height: 64px;
      color: $text-color-white;
      font-weight: 400;
      font-size: 1.75rem;
    }
  }

  .insurance-letter-title {
    text-transform: uppercase;
  }

  .clickable:hover {
    .insurance-letter,
    .insurance-letter-title-group {
      opacity: 0.8;
    }

    .insurance-letter-title {
      color: $btn-primary-background-color;
    }
  }

  .insurance-letter-title-group {
    h5 {
      line-height: 1.2;
    }

    h4 {
      line-height: 1.2;
    }
  }

  .separator {
    border-bottom: 1px solid #cecece;
    margin: 1rem 0;
  }

  .annual-cost {
    color: $btn-primary-background-color;
    font-size: 2.5rem;
    font-weight: 300;
    margin-bottom: 0;
  }

  &.collapsed {
    padding-bottom: 0px !important;

    .annual-cost {
      font-size: 2rem;
    }
  }

  .details {
    .details-label {
      font-size: 1.125rem;
      font-weight: 300;
      padding-top: 0.125rem;
      padding-bottom: 0.5rem;
      overflow: hidden;

      span {
        white-space: nowrap;
        display: block;

        &::after {
          content: "..............................................................................................."+ "...............................................................................................";
        }
      }
    }

    &.clickable .details-label:hover {
      color: $btn-primary-background-color;
    }

    .details-value {
      font-size: 2rem;
      font-weight: 300;
      white-space: nowrap;

      .value-suffix {
        font-size: 0.9rem;
        min-width: 35px;
        display: inline-block;
        text-align: left;
        padding-left: 5px;
      }
    }

    &.one-off .details-value,
    &.optional .details-value {
      font-size: 1.05rem;
    }

    &.optional {
      .details-label span {
        padding-left: 0.75rem;
        font-size: 0.9rem;
      }

      .details-value {
        font-size: 1rem;
      }
    }
  }

  .ProspectInsuranceSummary-footer {
    position: absolute;
    bottom: 0;

    &.clickable:hover {
      color: $btn-primary-background-color;
      cursor: pointer;
    }
  }

  .ProspectInsuranceSummary-withDetail {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-right: 9%;

    span{
      color: $btn-primary-background-color;
    }
  }
}

.ProspectInsuranceDetailSummary {
  .annual-cost {
    font-size: 4rem;
    font-weight: 300;

    .value-suffix {
      color: #888888;
    }
  }

  .example-title {
    font-size: 4rem;
    font-weight: 300;

    .value-suffix {
      color: #888888;
    }
  }

  .insurance-letter {
    display: inline-block;
    width: 168px;
    min-width: 168px;
    height: 168px;
    background: #f9fae9;
    text-align: center;

    span {
      display: inline-block;
      line-height: 168px;
      color: #265959;
      font-weight: 300;
      font-size: 5rem;
    }
  }
  h4 {
    font-weight: 300;
    line-height: 10px;
  }

  p {
    font-size: 1.5rem;
  }

  .details {
    align-items: baseline;
    line-height: 1.5;
    font-size: 1.25rem;

    .description {
      display: table;

      span {
        min-height: 1rem;
        display: block;
      }
    }

    .details-label {
      font-size: 1.5rem;
      font-weight: 300;
      overflow: hidden;

      span {
        white-space: nowrap;
        display: block;

        &::after {
          content: "..............................................................................................."+ "...............................................................................................";
        }
      }
    }

    &.clickable .details-label:hover {
      color: $btn-primary-background-color;
    }

    .details-value {
      font-size: 4rem;
      font-weight: 300;
      white-space: nowrap;
      overflow: hidden;

      .value-suffix {
        color: #888888;
        font-size: 1.5rem;
        min-width: 50px;
        display: inline-block;
        text-align: left;
        padding-left: 5px;
      }
    }

    .cost-value {
      @extend .details-value;
      font-size: 2rem;

      .value-suffix {
        min-width: 0;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .ProspectInsuranceSummary {
    .annual-cost {
      font-size: 2.3rem;
    }
  }
}

@include media-breakpoint-down(md) {
  .ProspectInsuranceDetailSummary {
    .details {
      .details-label {
        font-size: 1.1rem;
      }

      .details-value {
        font-size: 1.5rem;

        .value-suffix {
          font-size: 1.1rem;
        }
      }
    }
  }

  .ProspectInsuranceSummary {
    .annual-cost {
      font-size: 2.5rem;
    }
  }
}

.ProspectCreate {
  .message-container {
    background: #f9fae9;
    max-width: 500px;
    padding: 1rem 0.75rem;
    font-size: 1.25rem;
    font-weight: 300;
  }
  .btn-confirm {
    padding: 6px 60px;
    font-size: 1.25rem;
    font-weight: 300;
  }
}

.ProspectEdit {
  .form-group {
    .form-label {
      font-weight: 300;
    }
  }

  .confirm-section {
    font-weight: 500;
  }

  .check-pension-content {
    font-size: 0.85rem;
    font-weight: 500;
  }

  .ProspectEdit-partnership-logo img {
    width: 90%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  p {
    font-size: 1.25rem;
    font-weight: 300;
  }
  span {
    font-size: 1rem;
    font-weight: 300;
  }
  h6 span {
    font-weight: 400;
  }
}

.ProspectBasicView,
.ProspectDetailedView {
  .item-value {
    font-weight: 300;
  }
}

.ProspectView {
  margin-bottom: 25vh;

  .ScrollingButton {
    background-color: transparent;
    position: absolute;
    top: 50%;
    width: 28px;
    height: 28px;
    padding: 0;
    font-size: 32px;
    line-height: 12px;
    border-radius: 50%;
    border-color: #333333;
    color: #333333;
    opacity: 0.25;
    @include media-breakpoint-down(lg) {
      visibility: hidden;
    }
  }
}

@include media-breakpoint-down(sm) {
  .insurance-cost-details {
    h4 {
      font-size: 1.1rem;
    }

    h3.example-title,
    h3.annual-cost {
      font-size: 1.75rem;
    }
  }

  .ProspectInsuranceSummary {
    .annual-cost {
      font-size: 2.2rem;
    }
  }
}

.popover-button {
  background: transparent;
  border: none !important;
  outline: none !important;
}

.popover {
  max-width: 450px;
  height: 99vh;
  border: 0;
  overflow-y: auto;
  overflow-x: hidden;

  .popover-content{
    width: 400px;
    min-height: 100%;
    border: 1px solid rgba(0, 0, 0, .2);

    .card {
      border-radius: 5px;
    }
    .card-text span {
      display: block;
    }
  }
}

.menu-button{

  .dropdown-menu {
    min-width: inherit;
    border: 1px solid rgba(0, 0, 0, .2);
    text-align: center;
  }
}

#ProspectTableList {
  width: 100%;
  position: relative;
}

.broker-check-box .form-check-input {
  transform: scale(1.6);
  margin-top: 12px;
  border-color: #b4b8bb;
  cursor: pointer;
}

.broker-check-box .form-check-input:focus {
  outline: none;
  box-shadow: none;
}
