@import "./theme";

$enable-rounded: false;
$grid-gutter-width: 12px;
$body-color: $text-color-black;

$primary: $btn-primary-background-color;
$secondary: $btn-secondary-background-color;
$info: $btn-info-background-color;

$font-family-sans-serif: "din-condensed", sans-serif, tenon, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
  "Noto Color Emoji";
$font-weight-lighter: 300;
$font-weight-bolder: 400;
$headings-line-height: 1.5;

$font-size-base: 1rem;
$h1-font-size: $font-size-base * 4.5;
$h2-font-size: $font-size-base * 2.1;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.4;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

/* import bootstrap to set changes */
@import "./../../node_modules/bootstrap/scss/bootstrap";

/* Bootstrap overrides */
.btn-link {
  color: $btn-link-text-color;

  &:hover,
  &:not(:disabled):not(.disabled):active,
  &:focus,
  &.focus {
    color: $btn-link-text-color !important;
    background-color: #fff !important;
    border-color: #fff !important;
    box-shadow: none !important;
  }
}
